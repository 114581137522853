@for $i from 0 through 20 {
	.mt-#{$i} {
		margin-top: #{$i}px !important;
	}

	.mb-#{$i} {
		margin-bottom: #{$i}px !important;
	}

	.ml-#{$i} {
		margin-left: #{$i}px !important;
	}

	.mr-#{$i} {
		margin-right: #{$i}px !important;
	}

	.pt-#{$i} {
		padding-top: #{$i}px !important;
	}

	.pb-#{$i} {
		padding-bottom: #{$i}px !important;
	}

	.pl-#{$i} {
		padding-left: #{$i}px !important;
	}

	.pr-#{$i} {
		padding-right: #{$i}px !important;
	}
}

@for $i from 0 through 100 {
	.w-#{$i}-p {
		width: #{$i}px !important;
	}

	.rem-#{$i} {
		width: #{$i}rem !important;
	}
}

.display-flex {
	display: flex;
}

.flex-1 {
	flex: 1;
}

.flex-grow-1 {
	flex-grow: 1;
}

.flex-wrap {
	flex-wrap: wrap;
}

.w-50 {
	width: 50% !important;
}

.w-100 {
	width: 100% !important;
}

.white {
	color: white !important;
}

.red {
	color: red !important;
}

.position-relative {
	position: relative !important;
}

.font-weight-bold {
	font-weight: bold !important;
}
